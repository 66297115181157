import { z } from 'zod'

export const TransferSchema = z.object({
  beneficiary: z.string(),
  IBAN: z.string(),
  amount: z.number(),
  currency: z.string().length(3),
  paymentReference: z.string(),
})

export type Transfer = z.infer<typeof TransferSchema>
