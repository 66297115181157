import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Base64 } from 'js-base64'
import axios from 'axios'
import { Transfer, TransferSchema } from '../../../schemas/Transfer'
import Toast from '../../../components/Toast/Toast'
import arrowPath from './arrow-path.svg'
import FileDropZone from '../../../components/FileDropZone/FileDropZone'
import arrowCurly from './arrow-curly.svg'

const NewTransfer: React.FC = () => {
  const [description, setDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null)
  const navigate = useNavigate()

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!description) {
      setToast({ message: 'Description is required.', type: 'error' })
      return
    }

    setLoading(true)
    try {
      const res = await axios.post('/api/transfer', { description })
      const transfer: Transfer = TransferSchema.parse(res.data)
      const encodedTransfer = Base64.encode(JSON.stringify(transfer))
      navigate(`/transfer/${encodedTransfer}`)
      setToast({ message: 'Transfer created successfully!', type: 'success' })
    } catch (err) {
      console.log(err)
      setToast({ message: 'An error occurred while creating the transfer.', type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleFileDrop = async (files: FileList) => {
    setLoading(true)
    setToast({ message: 'Uploading file...', type: 'info' })
    try {
      const file = files[0]
      const response = await axios.get<{ signedUrl: string; fileName: string }>('/api/upload/URL')
      const { signedUrl, fileName } = response.data
      const renamedFile = new File([file], fileName, { type: file.type })
      await axios.put(signedUrl, renamedFile, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
      const transferResponse = await axios.post('/api/transfer', { fileName })
      const transfer: Transfer = TransferSchema.parse(transferResponse.data)
      const encodedTransfer = Base64.encode(JSON.stringify(transfer))
      navigate(`/transfer/${encodedTransfer}`)
    } catch (err) {
      console.log(err)
      setToast({ message: 'An error occurred while creating the transfer.', type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!loading && (
        <FileDropZone onDrop={handleFileDrop} backgroundColor="bg-primary-500">
          <p className="text-white text-4xl font-semibold">Drop it!</p>
        </FileDropZone>
      )}
      <div className="max-w-screen-md mx-auto px-5">
        {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
        <form onSubmit={handleSubmit} className="flex flex-col gap-6 sm:items-end">
          <textarea
            className="p-4 w-full border border-gray-300 rounded-lg focus:border-primary-500"
            placeholder="Enter a description of a SEPA wire transfer. Ensure the text includes the IBAN and the amount."
            value={description}
            onChange={handleInputChange}
            rows={6}
            disabled={loading}
          />
          <button
            type="submit"
            className={`px-4 py-2 bg-primary-400 text-white rounded-full hover:bg-primary-500 ${loading ? 'opacity-75' : ''}`}
            disabled={loading}
          >
            {loading ? <img className="size-4 animate-spin" src={arrowPath} alt="Arrow Icon" /> : 'Next'}
          </button>
        </form>
        <div className="m-10 flex">
          <p className="text-primary-500 font-bold text-2xl text-center text-nowrap self-end rotate-6 ">Or drop a PDF</p>
          <img className="h-auto w-16" src={arrowCurly} alt="Arrow Icon" />
        </div>
      </div>
    </>
  )
}

export default NewTransfer
