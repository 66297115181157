import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

const Main = () => {
  return (
    <>
      <header className="container mx-auto">
        <h1 className="my-16 mx-4 text-4xl sm:text-6xl font-semibold text-center">
          Make SEPA transfers <br />
          <span className="text-primary-500">smooth</span>
        </h1>
      </header>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default Main
