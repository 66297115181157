import React, { useEffect, useState } from 'react'
import axios from 'axios'

interface HealthCheckResponse {
  // Define the expected structure of the response data here
  status: string
  timestamp: string
  // Add other fields as needed
}

const HelthCheck: React.FC = () => {
  const [data, setData] = useState<HealthCheckResponse | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<HealthCheckResponse>('/api/health-check')
        setData(response.data)
      } catch (error) {
        setError(error as Error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  let value = 'Loading...'
  if (error) value = 'Error: ' + error.message
  if (data) value = JSON.stringify(data)

  return (
    <div className="bg-white shadow-md rounded p-4">
      <div className="text-gray-800">{value}</div>
    </div>
  )
}

export default HelthCheck
