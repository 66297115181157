import React, { useEffect, useState } from 'react'
import check from './check.svg'
import error from './error.svg'

interface ToastProps {
  message: string
  type: 'info' | 'success' | 'error'
  onClose: () => void
}

const Toast: React.FC<ToastProps> = ({ message, type, onClose }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
    const timer = setTimeout(() => {
      setShow(false)
      setTimeout(onClose, 300) // Wait for animation to complete before calling onClose
    }, 5000)
    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <div
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 rounded shadow-lg transition-transform duration-300 ${
        show ? 'translate-y-0' : 'translate-y-20'
      } ${type === 'error' ? 'bg-red-500' : type === 'info' ? 'bg-green-500' : 'bg-primary-500'} text-white flex items-center`}
    >
      {type !== 'info' && <img className="h-6 w-6 mr-2" src={type === 'error' ? error : check} />}
      <span>{message}</span>
      <button className="ml-4" onClick={() => setShow(false)}>
        ✕
      </button>
    </div>
  )
}

export default Toast
