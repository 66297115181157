import React, { useState, DragEvent as ReactDragEvent, useEffect, ReactNode } from 'react'

interface FileDropZoneProps {
  onDrop: (files: FileList) => void
  allowedTypes?: string[]
  backgroundColor?: string
  children?: ReactNode
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onDrop, allowedTypes = ['application/pdf'], backgroundColor = 'bg-gray-500', children }) => {
  const [isDropZoneVisible, setDropZoneVisible] = useState(false)

  const allowDrag = (e: ReactDragEvent<HTMLDivElement> | DragEvent) => {
    if (!e.dataTransfer) return

    const items = e.dataTransfer.items
    let validFile = false

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file' && allowedTypes.includes(items[i].type)) {
        validFile = true
        break
      }
    }

    if (validFile) {
      e.dataTransfer.dropEffect = 'copy'
      e.preventDefault()
      showDropZone()
    } else {
      hideDropZone()
    }
  }

  const showDropZone = () => {
    setDropZoneVisible(true)
  }

  const hideDropZone = () => {
    setDropZoneVisible(false)
  }

  const handleDrop = (e: ReactDragEvent<HTMLDivElement>) => {
    e.preventDefault()
    hideDropZone()
    onDrop(e.dataTransfer!.files)
  }

  useEffect(() => {
    const handleWindowDragEnter = (e: DragEvent) => {
      if (!e.dataTransfer) return
      allowDrag(e)
    }

    window.addEventListener('dragenter', handleWindowDragEnter)

    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter)
    }
  }, [])

  return (
    <div
      onDragEnter={allowDrag}
      onDragOver={allowDrag}
      onDragLeave={hideDropZone}
      onDrop={handleDrop}
      className={`${backgroundColor} ${isDropZoneVisible ? 'fixed' : 'hidden'} w-full h-full top-0 left-0 opacity-70 z-50 flex items-center justify-center`}
    >
      {children}
    </div>
  )
}

export default FileDropZone
