import React, { useState, useEffect } from 'react'
import notfound from './notfound.jpg'

const PageNotFound = () => {
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className="container flex flex-col mx-auto gap-5 items-center mt-5">
      <img className="max-w-3xl w-full max-h-450px" src={notfound} alt="Page not found" />
      <h1 className=" text-primary-500 text-3xl font-bold">Page not found!</h1>
    </div>
  )
}

export default PageNotFound
