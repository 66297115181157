import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode.react'
import { Transfer } from '../../../schemas/Transfer'
import { useNavigate, useLoaderData, LoaderFunctionArgs } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { Base64 } from 'js-base64'
import Toast from '../../../components/Toast/Toast'
import arrow from './arrow.svg'

const generateSepaQrCode = (transfer: Transfer) => {
  const sepaValues = [
    'BCD', // Service tag
    '002', // Version
    '1', // Character set
    'SCT', // Identification
    '', // BIC (optional)
    transfer.beneficiary, // Name
    transfer.IBAN, // IBAN
    transfer.amount > 0 ? `${transfer.currency}${transfer.amount.toFixed(2)}` : '', // Amount
    '', // Purpose (optional)
    transfer.paymentReference, // Remittance information
    '', // Unstructured remittance information (optional)
    '', // Beneficiary to originator information (optional),
  ]
  return sepaValues.join('\n')
}

const ShowTransfer: React.FC = () => {
  const { transfer: loaderData } = useLoaderData() as { transfer: Transfer }
  const [transfer, setTransfer] = useState<Transfer>(loaderData)
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null)
  const navigate = useNavigate()
  const [, copyToClipboard] = useCopyToClipboard()

  useEffect(() => {
    setTransfer(loaderData)
  }, [loaderData])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTransfer({ ...transfer, [name]: value })
  }

  const handleAmountChange = (valueString: string) => {
    const value = parseFloat(valueString)
    setTransfer({ ...transfer, amount: isNaN(value) ? 0 : value })
  }

  const handleShare = () => {
    try {
      const encodedTransfer = Base64.encode(JSON.stringify(transfer))
      const url = `/transfer/${encodedTransfer}`
      navigate(url)
      copyToClipboard(`${window.location.origin}${url}`)

      setToast({ message: 'The URL has been copied to your clipboard.', type: 'success' })
    } catch (error) {
      console.error('Error encoding transfer data: ', error)
      setToast({ message: 'Failed to generate the URL.', type: 'error' })
    }
  }

  return (
    <>
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
      <div className="flex md:flex-row flex-col items-center gap-32 mx-4 justify-center">
        <div className="relative md:order-last">
          <QRCode value={generateSepaQrCode(transfer)} size={150} level="M" includeMargin />
          <div className="absolute flex h-16 w-max items-start gap-1 -bottom-20 -left-20">
            <p className="text-primary-500 font-bold text-lg text-center text-nowrap self-end rotate-6">Scan to pay</p>
            <img className="h-auto w-16" src={arrow} alt="Arrow Icon" />
          </div>
        </div>
        <div className="w-full max-w-96  text-gray-600">
          <div className="mb-3 flex items-center">
            <label className="mb-0 w-32 text-left">Beneficiary</label>
            <input
              name="beneficiary"
              value={transfer.beneficiary}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-3 flex items-center">
            <label className="mb-0 w-32 text-left">IBAN</label>
            <input name="IBAN" value={transfer.IBAN} onChange={handleChange} className="w-full md:w-96 px-3 py-2 border border-gray-300 rounded" />
          </div>
          <div className="mb-3 flex items-center">
            <label className="mb-0 w-32 text-left">Amount</label>
            <input
              name="amount"
              value={transfer.amount}
              onChange={(e) => handleAmountChange(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-3 flex items-center">
            <label className="mb-0 w-32 text-left">Currency</label>
            <input name="currency" value={transfer.currency} onChange={handleChange} className="w-full px-3 py-2 border border-gray-300 rounded" />
          </div>
          <div className="mb-3 flex items-center">
            <label className="mb-0 w-32 text-left">Payment Reference</label>
            <input
              name="paymentReference"
              value={transfer.paymentReference}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2  mx-4 my-6 justify-center">
        <button onClick={() => navigate('/new')} className=" bg-primary-400 text-white rounded-full px-4 py-2 mr-2 hover:bg-primary-500">
          New
        </button>
        <button onClick={handleShare} className=" bg-primary-400 text-white rounded-full px-4 py-2 hover:bg-primary-500">
          Share
        </button>
      </div>
    </>
  )
}

export default ShowTransfer

export const transferLoader = async ({ params }: LoaderFunctionArgs) => {
  const encodedTransfer = params.transfer
  if (encodedTransfer) {
    const decodedTransfer = JSON.parse(Base64.decode(encodedTransfer))
    return { transfer: decodedTransfer }
  }
  return { transfer: null }
}
