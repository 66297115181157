import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import HelthCheck from './pages/HelthCheck/HelthCheck'
import Main from './pages/Main/Main'
import NewTransfer from './pages/Main/NewTransfer.tsx/NewTransfer'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import ShowTransfer, { transferLoader } from './pages/Main/ShowTransfer/ShowTransfer'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      { index: true, element: <Navigate to="/new" replace /> },
      { path: 'new', element: <NewTransfer /> },
      { path: 'transfer/:transfer', element: <ShowTransfer />, loader: transferLoader },
    ],
  },
  { path: '/health-check', element: <HelthCheck /> },
  { path: '*', element: <PageNotFound /> },
])

const rootElement = document.getElementById('root')
if (rootElement == null) {
  throw new Error('Failed to find the root element')
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
